/* @noflow */
/* eslint-disable react/no-unescaped-entities, react/jsx-filename-extension */
import React, {FC} from 'react';
import {css} from '@emotion/core';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

import {chromeExtensionLink} from '../../../constants';
// @ts-expect-error
import Modal from '../../Modal';
import Button from '../../Button';
// @ts-expect-error
import FooterDark from '../../FooterDark';
import MapquestLogo from '../../../assets/svg/mapquest-logo-black.svg';
// @ts-expect-error
import TermsOfService from '../../TermsOfService';
// @ts-expect-error
import currentBrowserName from '../../../util/detectBrowser';
import {ExperimentProps} from '../type';

const IndexGreenCta: FC<ExperimentProps> = ({onCtaClick}) => {
    const site = useStaticQuery(graphql`
        query IndexGreenCta {
            map: file(relativePath: {eq: "mapLosAngeles.png"}) {
                childImageSharp {
                    fixed(width: 1200, quality: 30) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `);

    return (
        <>
            <Img
                fixed={site.map.childImageSharp.fixed}
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    zIndex: '0'
                }}
                imgStyle={{
                    zIndex: '0',
                    position: 'relative'
                }}
            />
            <Modal marginTop="40px" maxWidth="750px" innerMargin="0px" height="100%">
                <div
                    css={css`
                        margin: 1rem 0 2.5rem;
                        @media only screen and (max-width: 600px) {
                            margin: 1rem 0 1rem;
                        }
                    `}
                >
                    <MapquestLogo />
                </div>
                <h1
                    css={css`
                        font-size: 2rem;
                        font-weight: 600;
                        margin-bottom: 1rem;
                        line-height: 2.75rem;
                        letter-spacing: 0.05rem;
                        @media (max-width: 600px) {
                            font-size: 1.5rem;
                            line-height: normal;
                        }
                        @media only screen and (max-width: 600px) {
                            font-size: 1rem;
                        }
                    `}
                >
                    Quick Driving Directions <br />
                    by MapQuest™
                </h1>
                <p
                    css={css`
                        font-size: 0.95rem;
                        margin-bottom: 1.5rem;
                        @media only screen and (max-width: 600px) {
                            font-size: 12px;
                        }
                    `}
                >
                    Install the new MapQuest {currentBrowserName()}™ extension to quickly access step-by-step directions
                    for all your trips, as well as an updated search experience, on your new tab.
                </p>
                <Button
                    text="CONTINUE"
                    ariaLabel="CONTINUE"
                    link={chromeExtensionLink}
                    onClick={onCtaClick}
                    overrideButtonStyles="span.continue{ color:rgb(51, 51, 51); }"
                />
                <div
                    className="instructions"
                    css={css`
                        background: #f4f4f4;
                        padding: 1rem 2rem;
                        border-radius: 8px;
                        margin: 1.75rem 0 1rem;
                        width: 100%;
                    `}
                >
                    <p
                        css={css`
                            font-size: 0.95rem;
                        `}
                    >
                        Step 1: Click the “Continue” Button {'>'} Step 2: Add the {currentBrowserName()}™ <br />
                        Extension {'>'} Step 3: Find your way with MapQuest!
                    </p>
                </div>
                <TermsOfService
                    textStyles={`
                    font-size: 0.7rem;
                    a {
                        text-decoration: underline;
                    }`}
                />
            </Modal>
            <FooterDark />
        </>
    );
};

export default IndexGreenCta;
